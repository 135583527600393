<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <!-- <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }"> User List </b-link>
        for other users.
      </div>
    </b-alert> -->

    <b-tabs >
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">المعلومات الشخصية</span>
        </template>
        <!-- <user-edit-tab-account
          :user-data="userData"
          class="mt-2 pt-75"
        /> -->
        <user-add-tab-info class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon icon="FileIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">الوثائق</span>
        </template>
        <user-add-tab-document class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <feather-icon icon="LinkIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">الحساب</span>
        </template>
        <user-add-tab-bank class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import userStoreModule from "../userStoreModule";
import UserAddTabInfo from "./UserAddTabInfo.vue";
import UserAddTabDocument from "./UserAddTabDocument.vue";
import UserAddTabBank from "./UserAddTabBank.vue";
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserAddTabInfo,
    UserAddTabDocument,
    UserAddTabBank,
  },
  setup() {
    const userData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

  

    return {
      userData,
    };
  },
};
</script> 

<style></style>