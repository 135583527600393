<template>
  <b-form>
    <b-row>
      <b-col cols="12" md="4">
        <b-form-group label="Account Holder Name" label-for="full-name">
          <b-form-input id="full-name" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="Account Number" label-for="full-name">
          <b-form-input id="full-name" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group label="Blank Name" label-for="full-name">
          <b-form-input id="full-name" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="IfCS Code" label-for="full-name">
          <b-form-input id="full-name" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="Pan Number" label-for="full-name">
          <b-form-input id="full-name" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="Branch" label-for="full-name">
          <b-form-input id="full-name" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-2">
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <span> حفظ </span>
        </b-button>
        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <span> إعادة تعيين </span>
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
  },
  setup() {
   


    return {

    };
  },
};
</script> 

<style></style>